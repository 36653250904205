// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-brands-tsx": () => import("./../../../src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-digital-editions-tsx": () => import("./../../../src/pages/digital-editions.tsx" /* webpackChunkName: "component---src-pages-digital-editions-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-forgot-tsx": () => import("./../../../src/pages/forgot.tsx" /* webpackChunkName: "component---src-pages-forgot-tsx" */),
  "component---src-pages-govbuddy-tsx": () => import("./../../../src/pages/govbuddy.tsx" /* webpackChunkName: "component---src-pages-govbuddy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-maps-tsx": () => import("./../../../src/pages/maps.tsx" /* webpackChunkName: "component---src-pages-maps-tsx" */),
  "component---src-pages-policy-guru-tsx": () => import("./../../../src/pages/policy-guru.tsx" /* webpackChunkName: "component---src-pages-policy-guru-tsx" */),
  "component---src-pages-printed-directory-tsx": () => import("./../../../src/pages/printed-directory.tsx" /* webpackChunkName: "component---src-pages-printed-directory-tsx" */),
  "component---src-pages-pritchardu-tsx": () => import("./../../../src/pages/pritchardu.tsx" /* webpackChunkName: "component---src-pages-pritchardu-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-detail-tsx": () => import("./../../../src/pages/product-detail.tsx" /* webpackChunkName: "component---src-pages-product-detail-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sol-developers-tsx": () => import("./../../../src/pages/sol-developers.tsx" /* webpackChunkName: "component---src-pages-sol-developers-tsx" */),
  "component---src-pages-sol-government-tsx": () => import("./../../../src/pages/sol-government.tsx" /* webpackChunkName: "component---src-pages-sol-government-tsx" */),
  "component---src-pages-sol-grassroots-tsx": () => import("./../../../src/pages/sol-grassroots.tsx" /* webpackChunkName: "component---src-pages-sol-grassroots-tsx" */),
  "component---src-pages-sol-lobbyst-tsx": () => import("./../../../src/pages/sol-lobbyst.tsx" /* webpackChunkName: "component---src-pages-sol-lobbyst-tsx" */),
  "component---src-pages-sol-nonpro-tsx": () => import("./../../../src/pages/sol-nonpro.tsx" /* webpackChunkName: "component---src-pages-sol-nonpro-tsx" */),
  "component---src-pages-sol-trade-tsx": () => import("./../../../src/pages/sol-trade.tsx" /* webpackChunkName: "component---src-pages-sol-trade-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

