export const initialState = {
    cart: {
        products: null,
        subTotal: 0,
        total: 0
    },
    getCartProducts: () => null,
    maximumQuantity: () => null,
    minimumQuantity: () => null,
};

