import { productsData } from '../../Data/productsData';
import * as React from 'react';
import { GlobalContext } from './CreateContext';
import { initialState } from './InitialState/InitialState';
import { globalReducer } from './Reducers';

interface Props {
    children: JSX.Element[] | JSX.Element | React.ReactNode;
}

const GlobalProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(globalReducer, initialState);

    const getCartProducts = async () => {
        dispatch({
            type: 'CART_PRODUCTS',
            payload: productsData
        })
    }

    const maximumQuantity = async (id: number, value: number) => {
        const updatePrice = state.cart.products && state.cart.products?.filter(p => {
            if (p.id === id) {
                p.unit_price = Math.round(value * p.price)
                return p
            }
            return p
        })

        dispatch({
            type: 'CART_PRODUCTS',
            payload: updatePrice
        })
    }

    const minimumQuantity = async (id: number, value: number) => {
        const updatePrice = state.cart.products && state.cart.products?.filter(p => {
            if (p.id === id) {
                p.unit_price = Math.round(value * p.price)
                return p
            }
            return p
        })
        dispatch({
            type: 'CART_PRODUCTS',
            payload: updatePrice
        })
    }


    return (
        <GlobalContext.Provider
            value={{
                ...state,
                getCartProducts,
                maximumQuantity,
                minimumQuantity,
            }}
        >
            {children}
        </GlobalContext.Provider >
    );
};
export default GlobalProvider;