/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ProductsInterface } from "Components/Cart/CartInput";
import { DefaultContextState } from "../Interfaces";


// create a reducer similar to redux
export const globalReducer = (
    state: DefaultContextState,
    action: {
        type: string;
        payload: any
    }
) => {
    switch (action.type) {
        case 'CART_PRODUCTS':

            const totals = action.payload && action.payload?.reduce((sum: number, i: { unit_price: number; }) => (
                sum += i.unit_price
            ), 0)

            return {
                ...state,
                products: state.cart.products = action.payload,
                subTotal: state.cart.subTotal = totals
            };


        default:
            return state;
    }
};