import Imgs from '../Assets/sample.webp'

export const productsData = [
    {
        id: 1,
        img: Imgs,
        title: '2021 Pocket Directory of the California Legislature',
        note: 'Quantity discounts available',
        price: 17,
        unit_price: 17,
    },
    {
        id: 2,
        img: Imgs,
        title: '2020 Pocket Directory of the California Legislature',
        note: 'Quantity discounts available',
        price: 19,
        unit_price: 19,
    },
    {
        id: 3,
        img: Imgs,
        title: '2020 Pocket Directory of the California Legislature',
        note: 'Quantity discounts available',
        price: 13,
        unit_price: 13,
    },
    {
        id: 4,
        img: Imgs,
        title: '2020 Pocket Directory of the California Legislature',
        note: 'Quantity discounts available',
        price: 28,
        unit_price: 28,
    }
];