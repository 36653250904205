module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://44bb0565459c44428e76fd2b96514e81@o997824.ingest.sentry.io/5956154"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
