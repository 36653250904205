/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { DefaultContextState } from './Interfaces';

export const GlobalContext = React.createContext<DefaultContextState>({
    cart: {
        products: null,
        subTotal: 0,
        total: 0
    },
    getCartProducts: () => { },
    maximumQuantity: () => { },
    minimumQuantity: () => { },
});